<template>
  <div class="pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-32" :class="$style.container">
    <div class="font-weight-bold mb-3">Page not found</div>
    <div class="text-gray-6 font-size-24">
      This page is deprecated, deleted, or does not exist at all
    </div>
    <div class="font-weight-bold font-size-70 mb-1">404 —</div>
    <a href="javascript: void(0);" class="btn btn-outline-primary width-100">Go Back</a>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral25',
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
