<template>
  <div>
    <div class="font-size-21 mb-2 font-weight-bold">Average order</div>
    <div class="d-flex align-items-end flex-wrap">
      <div class="pr-3 mr-auto">
        <i class="fe fe-server font-size-48 text-gray-5" />
      </div>
      <div class="font-size-36 font-weight-bold">$16,942.09</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral24v1',
}
</script>
