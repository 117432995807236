<template>
  <div class="d-flex flex-nowrap align-items-start">
    <div class="mr-4 flex-shrink-0 align-self-start vb__utils__avatar vb__utils__avatar--size64">
      <img src="resources/images/avatars/3.jpg" alt="Mary Stanform" />
    </div>
    <div class="flex-grow-1">
      <div class="border-bottom">
        <div class="d-flex flex-wrap mb-2">
          <div class="mr-auto">
            <div class="text-gray-6">
              <span class="text-dark font-weight-bold">Helen maggie</span>
              posted
            </div>
            <div>Few seconds ago</div>
          </div>
          <div class="nav-item dropdown">
            <a-dropdown placement="bottomRight" :trigger="['click']">
              <a class="nav-link dropdown-toggle pt-sm-0" href="javascript: void(0);">Actions</a>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a href="javascript:;">Action</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;">Another action</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;">Something else here</a>
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item>
                    <a href="javascript:;">Separated link</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
        <div class="mb-3">
          Lorem ipsum dolor sit amit,consectetur eiusmdd tempory
          <br />incididunt ut labore et dolore magna elit
        </div>
        <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
          <a class="text-blue mr-3" href="javascript: void(0);">
            <i class="fe fe-heart mr-1" /> 61 Likes
          </a>
          <a class="text-blue mr-3" href="javascript: void(0);">
            <i class="fe fe-message-square mr-1" /> 2 Comments
          </a>
        </div>
      </div>
      <div class="d-flex flex-nowrap align-items-start pt-4">
        <div
          class="mr-4 flex-shrink-0 align-self-start vb__utils__avatar vb__utils__avatar--size64"
        >
          <img src="resources/images/avatars/1.jpg" alt="Mary Stanform" />
        </div>
        <div class="flex-grow-1">
          <div class="border-bottom">
            <div class="d-flex flex-wrap mb-2">
              <div class="mr-auto">
                <div class="text-gray-6">
                  <span class="text-dark font-weight-bold">Helen maggie</span>
                  posted
                </div>
                <div>Few seconds ago</div>
              </div>
              <div class="nav-item dropdown">
                <a-dropdown placement="bottomRight" :trigger="['click']">
                  <a class="nav-link dropdown-toggle pt-sm-0" href="javascript: void(0);"
                    >Actions</a
                  >
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;">Action</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;">Another action</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;">Something else here</a>
                      </a-menu-item>
                      <a-menu-divider />
                      <a-menu-item>
                        <a href="javascript:;">Separated link</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
            <div class="mb-3">
              Lorem ipsum dolor sit amit,consectetur eiusmdd tempory
              <br />incididunt ut labore et dolore magna elit
            </div>
            <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
              <a class="text-blue mr-3" href="javascript: void(0);">
                <i class="fe fe-heart mr-1" /> 61 Likes
              </a>
              <a class="text-blue mr-3" href="javascript: void(0);">
                <i class="fe fe-message-square mr-1" /> 2 Comments
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-nowrap align-items-start pt-4">
        <div
          class="mr-4 flex-shrink-0 align-self-start vb__utils__avatar vb__utils__avatar--size64"
        >
          <img src="resources/images/avatars/4.jpg" alt="Mary Stanform" />
        </div>
        <div class="flex-grow-1">
          <div class="border-bottom">
            <div class="d-flex flex-wrap mb-2">
              <div class="mr-auto">
                <div class="text-gray-6">
                  <span class="text-dark font-weight-bold">Helen maggie</span>
                  posted
                </div>
                <div>Few seconds ago</div>
              </div>
              <div class="nav-item dropdown">
                <a-dropdown placement="bottomRight" :trigger="['click']">
                  <a class="nav-link dropdown-toggle pt-sm-0" href="javascript: void(0);"
                    >Actions</a
                  >
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;">Action</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;">Another action</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;">Something else here</a>
                      </a-menu-item>
                      <a-menu-divider />
                      <a-menu-item>
                        <a href="javascript:;">Separated link</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
            <div class="mb-3">
              Lorem ipsum dolor sit amit,consectetur eiusmdd tempory
              <br />incididunt ut labore et dolore magna elit
            </div>
            <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
              <a class="text-blue mr-3" href="javascript: void(0);">
                <i class="fe fe-heart mr-1" /> 61 Likes
              </a>
              <a class="text-blue mr-3" href="javascript: void(0);">
                <i class="fe fe-message-square mr-1" /> 2 Comments
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-nowrap align-items-start pt-4">
        <div
          class="mr-4 flex-shrink-0 align-self-start vb__utils__avatar vb__utils__avatar--size64"
        >
          <img src="resources/images/avatars/5.jpg" alt="Mary Stanform" />
        </div>
        <div class="flex-grow-1">
          <div class="border-bottom">
            <div class="d-flex flex-wrap mb-2">
              <div class="mr-auto">
                <div class="text-gray-6">
                  <span class="text-dark font-weight-bold">Helen maggie</span>
                  posted
                </div>
                <div>Few seconds ago</div>
              </div>
              <div class="nav-item dropdown">
                <a-dropdown placement="bottomRight" :trigger="['click']">
                  <a class="nav-link dropdown-toggle pt-sm-0" href="javascript: void(0);"
                    >Actions</a
                  >
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;">Action</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;">Another action</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;">Something else here</a>
                      </a-menu-item>
                      <a-menu-divider />
                      <a-menu-item>
                        <a href="javascript:;">Separated link</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
            <div class="mb-3">
              Lorem ipsum dolor sit amit,consectetur eiusmdd tempory
              <br />incididunt ut labore et dolore magna elit
            </div>
            <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
              <a class="text-blue mr-3" href="javascript: void(0);">
                <i class="fe fe-heart mr-1" /> 61 Likes
              </a>
              <a class="text-blue mr-3" href="javascript: void(0);">
                <i class="fe fe-message-square mr-1" /> 2 Comments
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-nowrap align-items-start pt-4">
        <div
          class="mr-4 flex-shrink-0 align-self-start vb__utils__avatar vb__utils__avatar--size64"
        >
          <img src="resources/images/avatars/2.jpg" alt="Mary Stanform" />
        </div>
        <div class="flex-grow-1">
          <div class="border-bottom">
            <div class="d-flex flex-wrap mb-2">
              <div class="mr-auto">
                <div class="text-gray-6">
                  <span class="text-dark font-weight-bold">Helen maggie</span>
                  posted
                </div>
                <div>Few seconds ago</div>
              </div>
              <div class="nav-item dropdown">
                <a-dropdown placement="bottomRight" :trigger="['click']">
                  <a class="nav-link dropdown-toggle pt-sm-0" href="javascript: void(0);"
                    >Actions</a
                  >
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;">Action</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;">Another action</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;">Something else here</a>
                      </a-menu-item>
                      <a-menu-divider />
                      <a-menu-item>
                        <a href="javascript:;">Separated link</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
            <div class="mb-3">
              Lorem ipsum dolor sit amit,consectetur eiusmdd tempory
              <br />incididunt ut labore et dolore magna elit
            </div>
            <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
              <a class="text-blue mr-3" href="javascript: void(0);">
                <i class="fe fe-heart mr-1" /> 61 Likes
              </a>
              <a class="text-blue mr-3" href="javascript: void(0);">
                <i class="fe fe-message-square mr-1" /> 2 Comments
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral15',
}
</script>
