<template>
  <div class="py-4 text-center">
    <div
      class="vb__utils__avatar vb__utils__avatar--rounded vb__utils__avatar--size84 border border-5 border-white d-inline-block mb-3"
    >
      <img src="resources/images/avatars/2.jpg" alt="Anna Gray" />
    </div>
    <div class="font-weight-bold font-size-24 text-dark">Anna Gray</div>
    <div class="font-size-18 text-gray-6 mb-4 mt-n1">CEO</div>
    <a href="#" class="btn btn-success">Add User</a>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral23',
}
</script>
