<template>
  <div class="d-flex flex-wrap" :class="$style.items">
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/1.png" alt="flowers-piece-1" />
    </div>
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/2.png" alt="flowers-piece-2" />
    </div>
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/3.png" alt="flowers-piece-3" />
    </div>
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/4.png" alt="flowers-piece-4" />
    </div>
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/5.png" alt="flowers-piece-5" />
    </div>
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/6.png" alt="flowers-piece-6" />
    </div>
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/7.png" alt="flowers-piece-7" />
    </div>
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/8.png" alt="flowers-piece-8" />
    </div>
    <div :class="$style.item">
      <img src="resources/images/content/flowers-pieces/9.png" alt="flowers-piece-9" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral8',
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
