<template>
  <div>
    <div class="d-flex flex-wrap align-items-center mb-2">
      <div class="flex-shrink-0 mr-4 mb-2 vb__utils__avatar">
        <img src="resources/images/avatars/5.jpg" alt="Mary Stanform" />
      </div>
      <div class="mb-2">
        <div class="text-dark font-size-18 font-weight-bold text-nowrap">
          Helen Maggie
          <i class="align-text-bottom fe fe-check-square text-success ml-2 font-size-24" />
        </div>
        <div class="text-uppercase">
          Support team
        </div>
      </div>
    </div>
    <div class="mb-3">
      <a href="javascript: void(0);" class="btn btn-outline-primary mr-2">Chat</a>
      <a href="javascript: void(0);" class="btn btn-outline-danger">Unfollow</a>
    </div>
    <div class="table-responsive">
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td class="text-gray-6 pl-0">
              Location
            </td>
            <td class="pr-0 text-right text-dark">
              New York
            </td>
          </tr>
          <tr>
            <td class="text-gray-6 pl-0">
              Phone
            </td>
            <td class="pr-0 text-right text-dark">
              +1 800 367 4784
            </td>
          </tr>
          <tr>
            <td class="text-gray-6 pl-0">
              Email
            </td>
            <td class="pr-0 text-right text-dark">
              mail@google.com
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral7',
}
</script>
