<template>
  <div class="d-flex flex-wrap">
    <div class="mb-3 mr-auto">
      <div class="vb__utils__avatarGroup mb-3">
        <div class="vb__utils__avatar vb__utils__avatar--size46">
          <img src="resources/images/avatars/1.jpg" alt="Mary Stanform" />
        </div>
        <div class="vb__utils__avatar vb__utils__avatar--size46">
          <img src="resources/images/avatars/2.jpg" alt="Mary Stanform" />
        </div>
        <div class="vb__utils__avatar vb__utils__avatar--size46">
          <img src="resources/images/avatars/3.jpg" alt="Mary Stanform" />
        </div>
        <div class="vb__utils__avatar vb__utils__avatar--size46">
          <img src="resources/images/avatars/4.jpg" alt="Mary Stanform" />
        </div>
        <button type="button" class="vb__utils__avatarGroupAdd">
          <i class="fe fe-plus" />
        </button>
      </div>
      <div class="d-flex align-items-start">
        <div class="vb__utils__avatar vb__utils__avatar--size64 mr-2">
          <img src="resources/images/avatars/4.jpg" alt="Mary Stanform" />
        </div>
        <div class="vb__utils__avatar vb__utils__avatar--size46">
          <img src="resources/images/avatars/4.jpg" alt="Mary Stanform" />
        </div>
      </div>
    </div>
    <div>
      <div class="vb__utils__avatarGroup mb-3">
        <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
          <img src="resources/images/avatars/1.jpg" alt="Mary Stanform" />
        </div>
        <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
          <img src="resources/images/avatars/2.jpg" alt="Mary Stanform" />
        </div>
        <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
          <img src="resources/images/avatars/3.jpg" alt="Mary Stanform" />
        </div>
        <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
          <img src="resources/images/avatars/4.jpg" alt="Mary Stanform" />
        </div>
        <button type="button" class="vb__utils__avatarGroupAdd">
          <i class="fe fe-plus" />
        </button>
      </div>
      <div class="d-flex align-items-start">
        <div class="vb__utils__avatar vb__utils__avatar--size64 vb__utils__avatar--rounded mr-2">
          <img src="resources/images/avatars/4.jpg" alt="Mary Stanform" />
        </div>
        <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
          <img src="resources/images/avatars/4.jpg" alt="Mary Stanform" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral9',
}
</script>
