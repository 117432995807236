<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL14' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-14 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL12' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-12 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL12V1' }" /></div>
        <div class="card">
          <div class="card-body text-white bg-primary rounded"><vb-widgets-general-12v1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL12V2' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-12v2 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL12V3' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-12v3 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL13' }" /></div>
        <div class="card"><vb-widgets-general-13 /></div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL13V1' }" /></div>
        <div class="card"><vb-widgets-general-13v1 /></div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL1V1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-1v1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL9' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-9 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL10' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-10 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL10V1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-10v1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL5V1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-5v1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL11' }" /></div>
        <div class="card"><vb-widgets-general-11 /></div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL11V1' }" /></div>
        <div class="card"><vb-widgets-general-11v1 /></div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL2' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-2 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL2V1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-2v1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL2V2' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-2v2 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL2V3' }" /></div>
        <div class="card">
          <div class="card-body text-white bg-success rounded"><vb-widgets-general-2v3 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL2V4' }" /></div>
        <div class="card">
          <div class="card-body text-white bg-danger rounded"><vb-widgets-general-2v4 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL3' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-3 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL3V1' }" /></div>
        <div class="card">
          <div class="card-body text-white bg-primary rounded"><vb-widgets-general-3v1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL4' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-4 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL5' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-5 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL6' }" /></div>
        <div class="card"><vb-widgets-general-6 /></div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL6V1' }" /></div>
        <div class="card"><vb-widgets-general-6v1 /></div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL7' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-7 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL8' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-8 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL16' }" /></div>
        <div class="card"><vb-widgets-general-16 /></div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL26' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-26 /></div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL15' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-15 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL17' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-17 /></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL17V1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-17v1 /></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL17V2' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-17v2 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL18' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-18 /></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL18V1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-18v1 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL19' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-19 /></div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL20' }" /></div>
        <div class="card"><vb-widgets-general-20 /></div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL21' }" /></div>
        <div class="card">
          <div class="card-body bg-success text-white rounded"><vb-widgets-general-21 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL21V2' }" /></div>
        <div class="card">
          <div class="card-body bg-light rounded"><vb-widgets-general-21v2 /></div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL20V1' }" /></div>
        <div class="card"><vb-widgets-general-20v1 /></div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL21V1' }" /></div>
        <div class="card">
          <div class="card-body bg-primary text-white rounded"><vb-widgets-general-21v1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL21V3' }" /></div>
        <div class="card">
          <div class="card-body bg-danger text-white rounded"><vb-widgets-general-21v3 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL22' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-22 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL23' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-23 /></div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL23V1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-23v1 /></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL24' }" /></div>
        <div class="card">
          <div class="card-body bg-primary text-white rounded"><vb-widgets-general-24 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL24V1' }" /></div>
        <div class="card">
          <div class="card-body bg-light text-dark rounded"><vb-widgets-general-24v1 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL25' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-25 /></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'GENERAL25V1' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-general-25v1 /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersTag from '@/@vb/widgets/Headers/Tag'
import VbWidgetsGeneral14 from '@/@vb/widgets/WidgetsGeneral/14'
import VbWidgetsGeneral12 from '@/@vb/widgets/WidgetsGeneral/12'
import VbWidgetsGeneral12v1 from '@/@vb/widgets/WidgetsGeneral/12v1'
import VbWidgetsGeneral12v2 from '@/@vb/widgets/WidgetsGeneral/12v2'
import VbWidgetsGeneral12v3 from '@/@vb/widgets/WidgetsGeneral/12v3'
import VbWidgetsGeneral13 from '@/@vb/widgets/WidgetsGeneral/13'
import VbWidgetsGeneral13v1 from '@/@vb/widgets/WidgetsGeneral/13v1'
import VbWidgetsGeneral1 from '@/@vb/widgets/WidgetsGeneral/1'
import VbWidgetsGeneral1v1 from '@/@vb/widgets/WidgetsGeneral/1v1'
import VbWidgetsGeneral9 from '@/@vb/widgets/WidgetsGeneral/9'
import VbWidgetsGeneral10 from '@/@vb/widgets/WidgetsGeneral/10'
import VbWidgetsGeneral10v1 from '@/@vb/widgets/WidgetsGeneral/10v1'
import VbWidgetsGeneral5v1 from '@/@vb/widgets/WidgetsGeneral/5v1'
import VbWidgetsGeneral11 from '@/@vb/widgets/WidgetsGeneral/11'
import VbWidgetsGeneral11v1 from '@/@vb/widgets/WidgetsGeneral/11v1'
import VbWidgetsGeneral2 from '@/@vb/widgets/WidgetsGeneral/2'
import VbWidgetsGeneral2v1 from '@/@vb/widgets/WidgetsGeneral/2v1'
import VbWidgetsGeneral2v2 from '@/@vb/widgets/WidgetsGeneral/2v2'
import VbWidgetsGeneral2v3 from '@/@vb/widgets/WidgetsGeneral/2v3'
import VbWidgetsGeneral2v4 from '@/@vb/widgets/WidgetsGeneral/2v4'
import VbWidgetsGeneral3 from '@/@vb/widgets/WidgetsGeneral/3'
import VbWidgetsGeneral3v1 from '@/@vb/widgets/WidgetsGeneral/3v1'
import VbWidgetsGeneral4 from '@/@vb/widgets/WidgetsGeneral/4'
import VbWidgetsGeneral5 from '@/@vb/widgets/WidgetsGeneral/5'
import VbWidgetsGeneral6 from '@/@vb/widgets/WidgetsGeneral/6'
import VbWidgetsGeneral6v1 from '@/@vb/widgets/WidgetsGeneral/6v1'
import VbWidgetsGeneral7 from '@/@vb/widgets/WidgetsGeneral/7'
import VbWidgetsGeneral8 from '@/@vb/widgets/WidgetsGeneral/8'
import VbWidgetsGeneral16 from '@/@vb/widgets/WidgetsGeneral/16'
import VbWidgetsGeneral26 from '@/@vb/widgets/WidgetsGeneral/26'
import VbWidgetsGeneral15 from '@/@vb/widgets/WidgetsGeneral/15'
import VbWidgetsGeneral17 from '@/@vb/widgets/WidgetsGeneral/17'
import VbWidgetsGeneral17v1 from '@/@vb/widgets/WidgetsGeneral/17v1'
import VbWidgetsGeneral17v2 from '@/@vb/widgets/WidgetsGeneral/17v2'
import VbWidgetsGeneral18 from '@/@vb/widgets/WidgetsGeneral/18'
import VbWidgetsGeneral18v1 from '@/@vb/widgets/WidgetsGeneral/18v1'
import VbWidgetsGeneral19 from '@/@vb/widgets/WidgetsGeneral/19'
import VbWidgetsGeneral20 from '@/@vb/widgets/WidgetsGeneral/20'
import VbWidgetsGeneral21 from '@/@vb/widgets/WidgetsGeneral/21'
import VbWidgetsGeneral21v2 from '@/@vb/widgets/WidgetsGeneral/21v2'
import VbWidgetsGeneral20v1 from '@/@vb/widgets/WidgetsGeneral/20v1'
import VbWidgetsGeneral21v1 from '@/@vb/widgets/WidgetsGeneral/21v1'
import VbWidgetsGeneral21v3 from '@/@vb/widgets/WidgetsGeneral/21v3'
import VbWidgetsGeneral22 from '@/@vb/widgets/WidgetsGeneral/22'
import VbWidgetsGeneral23 from '@/@vb/widgets/WidgetsGeneral/23'
import VbWidgetsGeneral23v1 from '@/@vb/widgets/WidgetsGeneral/23v1'
import VbWidgetsGeneral24 from '@/@vb/widgets/WidgetsGeneral/24'
import VbWidgetsGeneral24v1 from '@/@vb/widgets/WidgetsGeneral/24v1'
import VbWidgetsGeneral25 from '@/@vb/widgets/WidgetsGeneral/25'
import VbWidgetsGeneral25v1 from '@/@vb/widgets/WidgetsGeneral/25v1'

export default {
  name: 'VbGeneral',
  components: {
    VbHeadersTag,
    VbWidgetsGeneral14,
    VbWidgetsGeneral12,
    VbWidgetsGeneral12v1,
    VbWidgetsGeneral12v2,
    VbWidgetsGeneral12v3,
    VbWidgetsGeneral13,
    VbWidgetsGeneral13v1,
    VbWidgetsGeneral1,
    VbWidgetsGeneral1v1,
    VbWidgetsGeneral9,
    VbWidgetsGeneral10,
    VbWidgetsGeneral10v1,
    VbWidgetsGeneral5v1,
    VbWidgetsGeneral11,
    VbWidgetsGeneral11v1,
    VbWidgetsGeneral2,
    VbWidgetsGeneral2v1,
    VbWidgetsGeneral2v2,
    VbWidgetsGeneral2v3,
    VbWidgetsGeneral2v4,
    VbWidgetsGeneral3,
    VbWidgetsGeneral3v1,
    VbWidgetsGeneral4,
    VbWidgetsGeneral5,
    VbWidgetsGeneral6,
    VbWidgetsGeneral6v1,
    VbWidgetsGeneral7,
    VbWidgetsGeneral8,
    VbWidgetsGeneral16,
    VbWidgetsGeneral26,
    VbWidgetsGeneral15,
    VbWidgetsGeneral17,
    VbWidgetsGeneral17v1,
    VbWidgetsGeneral17v2,
    VbWidgetsGeneral18,
    VbWidgetsGeneral18v1,
    VbWidgetsGeneral19,
    VbWidgetsGeneral20,
    VbWidgetsGeneral21,
    VbWidgetsGeneral21v2,
    VbWidgetsGeneral20v1,
    VbWidgetsGeneral21v1,
    VbWidgetsGeneral21v3,
    VbWidgetsGeneral22,
    VbWidgetsGeneral23,
    VbWidgetsGeneral23v1,
    VbWidgetsGeneral24,
    VbWidgetsGeneral24v1,
    VbWidgetsGeneral25,
    VbWidgetsGeneral25v1,
  },
}
</script>
