<template>
  <div class="d-flex flex-wrap align-items-center">
    <div class="my-1 mr-4 font-size-36 flex-shrink-0">
      <i class="fe fe-cloud" />
    </div>
    <div>
      <div class="font-size-18 font-weight-bold text-dark">
        Cloud Uptime
      </div>
      <div class="font-size-12 text-uppercase">
        6 days 24 hours
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral3',
}
</script>
