<template>
  <div class="d-flex flex-wrap align-items-center">
    <i class="fe fe-server font-size-50 mr-3 text-gray-5" />
    <div class="text-dark">
      <div class="font-size-21 font-weight-bold">Databases</div>
      <div class="font-size-15">Total: 78,155</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral21v2',
}
</script>
