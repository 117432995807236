<template>
  <div>
    <div class="text-dark text-uppercase font-weight-bold mb-1">
      Work in progress
    </div>
    <p>Lorem ipsum dolor...</p>
    <div class="progress">
      <div
        class="progress-bar bg-success"
        style="width: 60%"
        role="progressbar"
        aria-valuenow="60"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'VbGeneral4',
}
</script>
